import Exception from '@/exceptions/Exception'

export default class UnknownPageException extends Exception {
	/**
	 * @param {number} pageNumber
	 */
	constructor (pageNumber) {
		const message = `The page at the position ${pageNumber} doesn't exist`
		super(message, 'UnknownPageException')
	}
}
