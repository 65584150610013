import { v4 as uuid } from 'uuid'

import UnknownSignatureAreaException from '@/exceptions/Signature/UnknownSignatureAreaException'
import UnknownPageException from '@/exceptions/Signature/UnknownPageException'
import SignatureService from '@/services/Signature/SignatureService'

export default {
	data: function () {
		return {
			signatureService: SignatureService,
			OAUTH_PROVIDER_JESIGNEXPERT: 'jesignexpert',
			QUEUED_HAS_SIGNATURES_REQUESTS: {}
		}
	},
	methods: {
		pollAuthentication: function (popup) {
			return new Promise((resolve, reject) => {
				if (popup && popup.closed) {
					this.signatureService.getAuthenticationUserStatus(this.OAUTH_PROVIDER_JESIGNEXPERT, this.userId)
						.then(status => {
							if (status && status.status != 'completed') {
								throw new Error('Authentication failed.')
							}
						})
						.then(() => {
							resolve()
						})
						.catch(error => {
							reject(error)
						})
				} else {
					let vm = this
					setTimeout(function () {
						vm.pollAuthentication(popup).then(resolve).catch(reject)
					}, 1000)
				}
			})
		},
		authenticateUser: function () {
			const params = { 'accounting-firm-id': this.accountingFirmId }
			return this.service
				.getAuthenticationUrl(this.OAUTH_PROVIDER_JESIGNEXPERT, params)
				.then(data => data['auth-url'])
				.then(authorizationUrl => {
					const popup = window.open(authorizationUrl.link, undefined, 'menubar=no,resizable=yes,status=no')
					return this.pollAuthentication(popup).catch(err => {
						throw err
					})
				})
				
		},

		hasSignatures: function () {
			let result = Promise.resolve(false)
			if (this.document) {
				if (this.document.hasOwnProperty('has_signatures')) {
					result = Promise.resolve(this.document.has_signatures)
				} else {
					result = this.signatureService.loadSignatures(this.vendorId, this.document.id)
				}
			}
			return result
		},
		findSignatureArea: function (signatureAreaId) {
			const result = this.signatureService.findSignatureArea(signatureAreaId)
			if (!result) {
				throw new UnknownSignatureAreaException(signatureAreaId)
			}
			return result
		},
		deleteSignatureArea: function (signatureAreaId) {
			const signatureArea = this.findSignatureArea(signatureAreaId)
			return this.signatureService.deleteSignatureArea(signatureAreaId).then(() => {
				const signatureAreas = this.signatureService.getSignatureAreas()
				if (signatureAreas.find(({ signatory }) => signatory.email === signatureArea.signatory.email)) {
					return
				}
				const order = signatureArea.signatory.order
				const promises = signatureAreas.map(({ signatory, id: areaId }) => {
					if (signatory.order > order) {
						return this.signatureService.updateSignatureArea({
							signatory: {
								...signatory,
								order: signatory.order - 1
							},
							id: areaId
						})
					}
					return Promise.resolve()
				})
				return Promise.all(promises)
			})
		},

		getSignatureAreas: function (pageNumber) {
			let result = this.signatureService.getSignatureAreas()
			if (!isNaN(pageNumber)) {
				this.findPageDOMElement(pageNumber)
				result = result.filter(signatureArea => signatureArea.page === pageNumber)
			}
			return result
		},

		// Signatory

		// DOM manipulations

		drawSignatureArea: function (context, { coords, page }, signatureAreaId = null) {
			let signatureArea = null
			if (signatureAreaId) {
				signatureArea = this.findSignatureArea(signatureAreaId)
			}
			const { height: realPageHeight, width: realPageWidth } = this.getPageRealDimensions(page)
			const pageContainer = document.getElementById(`signature-page-${page}`)
			const rect = pageContainer.getBoundingClientRect()
			let x = (realPageWidth / pageContainer.offsetWidth) * coords.x - (realPageWidth / pageContainer.offsetWidth) * rect.left
			if (x < 0) {
				x = 0
			} else if (x + this.DEFAULT_AREA_WIDTH > realPageWidth) {
				x = realPageWidth - this.DEFAULT_AREA_WIDTH
			}
			let y = (realPageHeight / pageContainer.offsetHeight) * coords.y - (realPageHeight / pageContainer.offsetHeight) * rect.top
			if (y < 0) {
				y = 0
			} else if (y + this.DEFAULT_AREA_HEIGHT > realPageHeight) {
				y = realPageHeight - this.DEFAULT_AREA_HEIGHT
			}
			coords = { x, y }
			let result
			if (signatureArea) {
				result = this.signatureService.updateSignatureArea({
					coords,
					page,
					id: signatureAreaId
				})
			} else {
				result = this.getSignatory(context).then(signatory =>
					this.signatureService.addSignatureArea({
						coords,
						id: uuid(),
						page,
						signatory
					})
				)
			}
			return result
		},

		getPageRealDimensions: function (page) {
			if (!(page instanceof HTMLElement)) {
				const pageNumber = page
				page = this.findPageDOMElement(pageNumber)
			}
			return {
				height: page.dataset.realHeight,
				width: page.dataset.realWidth
			}
		},

		convertCoordinatesForSending: function (signatureAreaId, { x, y }) {
			const signatureArea = this.findSignatureArea(signatureAreaId)
			const { height: realPageHeight } = this.getPageRealDimensions(signatureArea.page)
			let positionY = Math.round(realPageHeight - (y + this.DEFAULT_AREA_HEIGHT))
			if (positionY < 0) {
				positionY = 0
			} else if (positionY > realPageHeight - this.DEFAULT_AREA_HEIGHT) {
				positionY = Math.round(realPageHeight - this.DEFAULT_AREA_HEIGHT)
			}
			return {
				positionX: Math.round(x),
				positionY
			}
		},

		findPageDOMElement: function (pageNumber) {
			const result = document.getElementById(`signature-page-${pageNumber}`)
			if (!result) {
				throw new UnknownPageException(pageNumber)
			}
			return result
		}
	}
}
