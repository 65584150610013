// ============================================================================
// SignatureService
// -----------------------
// Signature module related services
// ============================================================================
// -------
// Imports
// -------
import { store } from '@/store'
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/SignatureApi'
// ---------
// Internals
// ---------
const Private = {
	requests: {
		document: {
			version: {
				signature: {
					create: function (vendorId, documentId, data = null, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, params: params })
						return API.document.version.signature.create(vendorId, documentId, data, params)
					},
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: NotImplementedYet,
					status: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, versionId, params = null) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return API.document.version.signature.status.read(vendorId, documentId, versionId, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					}
				},

				signatures: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return API.document.version.signatures.read(vendorId, documentId, params, true)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				signatories: {
					create: NotImplementedYet,
					read: function (accountingFirmId, vendorId, documentId, params = null) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, documentId: documentId })
						return API.document.version.signatories.read(accountingFirmId, vendorId, documentId, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			}
		},
		signatory: {
			create: NotImplementedYet,
			read: function (email, params = null) {
				RequiredArguments({ email: email })
				return API.signatory.read(email, params)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		},
		authentication: {
			url: {
				create: NotImplementedYet,
				read: function (oauthProviderIdentifier, params = null) {
					RequiredArguments({ oauthProviderIdentifier: oauthProviderIdentifier, params: params })
					return API.authentication.url.read(oauthProviderIdentifier, params)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			user: {
				status: {
					create: NotImplementedYet,
					read: function (oauthProviderIdentifier, userId, params = null) {
						RequiredArguments({ oauthProviderIdentifier: oauthProviderIdentifier, userId: userId })
						return API.authentication.user.status.read(oauthProviderIdentifier, userId, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			}
		}
	},
	service: {
		document: {
			version: {
				signature: {
					create: function (vendorId, documentId, data = null, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, params: params })
						return Private.requests.document.version.signature.create(vendorId, documentId, data, params)
					},
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: NotImplementedYet,
					status: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, versionId, params = null) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return Private.requests.document.version.signature.status.read(vendorId, documentId, versionId, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					}
				},
				signatures: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return Private.requests.document.version.signatures.read(vendorId, documentId, params)
					},
					load: function (vendorId, documentId, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return Private.service.document.version.signatures
							.read(vendorId, documentId, params)
							.then(signatures => signatures.length > 0)
							.then(documentHasSignatures => {
								Private.store.document.version.signatures.add(documentHasSignatures)
							})
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				signatories: {
					create: NotImplementedYet,
					read: function (accountingFirmId, vendorId, documentId, params = null) {
						RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, documentId: documentId })
						return Private.requests.document.version.signatories.read(accountingFirmId, vendorId, documentId, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			}
		},
		signatory: {
			create: NotImplementedYet,
			read: function (email, params = null) {
				RequiredArguments({ email: email })
				return Private.requests.signatory.read(email, params)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		},
		authentication: {
			url: {
				create: NotImplementedYet,
				read: function (oauthProviderIdentifier, params = null) {
					RequiredArguments({ oauthProviderIdentifier: oauthProviderIdentifier, params: params })
					return Private.requests.authentication.url.read(oauthProviderIdentifier, params)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			user: {
				status: {
					create: NotImplementedYet,
					read: function (oauthProviderIdentifier, userId, params = null) {
						RequiredArguments({ oauthProviderIdentifier: oauthProviderIdentifier, userId: userId })
						return Private.requests.authentication.user.status.read(oauthProviderIdentifier, userId, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			}
		}
	},
	store: {
		vendor: {
			structure: {
				get: store.state.documents.treeStructure
			}
		},
		document: {
			addOrUpdate: function (node) {
				RequiredArguments({ node: node })
				return store.dispatch('documents/addOrUpdateNodeInStructure', node)
			},
			version: {
				signatures: {
					add: function (signatures) {
						RequiredArguments({ signatures: signatures })
						return store.dispatch('documents/setVersionSignatures', signatures)
					}
				},
				signature: {
					area: {
						add: function (signatureArea) {
							RequiredArguments({ signatureAreas: signatureArea })
							return store.dispatch('signature/addSignatureArea', signatureArea)
						},
						update: function (signatureAreas) {
							RequiredArguments({ signatureAreas: signatureAreas })
							return store.dispatch('signature/updateSignatureArea', signatureAreas)
						},
						remove: function (signatureAreaId) {
							RequiredArguments({ signatureAreaId: signatureAreaId })
							return store.dispatch('signature/deleteSignatureArea', signatureAreaId)
						},
						find: function (signatureAreaId) {
							RequiredArguments({ signatureAreaId: signatureAreaId })
							return store.state.signature.list.find(signatureArea => signatureArea.id === signatureAreaId)
						}
					},
					status: {
						add: function (signatureStatus) {
							RequiredArguments({ signatureStatus: signatureStatus })
							return store.dispatch('documents/setVersionSignatureStatus', signatureStatus)
						}
					},
					areas: {
						list: function () {
							return store.state.signature.list
						},
						remove: function () {
							return store.dispatch('signature/deleteSignatureAreas')
						}
					}
				}
			}
		}
	}
}
// -------
// Exports
// -------
export default {
	createSignature: Private.service.document.version.signature.create,
	getSignature: Private.service.document.version.signature.read,
	updateSignature: Private.service.document.version.signature.update,
	deleteSignature: Private.service.document.version.signature.delete,

	createSignatureStatus: Private.service.document.version.signature.status.create,
	getSignatureStatus: Private.service.document.version.signature.status.read,
	updateSignatureStatus: Private.service.document.version.signature.status.update,
	deleteSignatureStatus: Private.service.document.version.signature.status.delete,

	createSignatures: Private.service.document.version.signatures.create,
	getSignatures: Private.service.document.version.signatures.read,
	loadSignatures: Private.service.document.version.signatures.load,
	updateSignatures: Private.service.document.version.signatures.update,
	deleteSignatures: Private.service.document.version.signatures.delete,

	createSignatories: Private.service.document.version.signatories.create,
	getSignatories: Private.service.document.version.signatories.read,
	updateSignatories: Private.service.document.version.signatories.update,
	deleteSignatories: Private.service.document.version.signatories.delete,

	createSignatory: Private.service.signatory.create,
	getSignatory: Private.service.signatory.read,
	updateSignatory: Private.service.signatory.update,
	deleteSignatory: Private.service.signatory.delete,

	createAuthenticationUrl: Private.service.authentication.url.create,
	getAuthenticationUrl: Private.service.authentication.url.read,
	updateAuthenticationUrl: Private.service.authentication.url.update,
	deleteAuthenticationUrl: Private.service.authentication.url.delete,

	createAuthenticationUserStatus: Private.service.authentication.user.status.create,
	getAuthenticationUserStatus: Private.service.authentication.user.status.read,
	updateAuthenticationUserStatus: Private.service.authentication.user.status.update,
	deleteAuthenticationUserStatus: Private.service.authentication.user.status.delete,

	addSignatureArea: Private.store.document.version.signature.area.add,
	updateSignatureArea: Private.store.document.version.signature.area.update,
	deleteSignatureArea: Private.store.document.version.signature.area.remove,

	getSignatureAreas: Private.store.document.version.signature.areas.list,

	findSignatureArea: Private.store.document.version.signature.area.find,
	deleteSignatureAreas: Private.store.document.version.signature.areas.remove
}
