import Exception from '@/exceptions/Exception'

export default class UnknownSignatureAreaException extends Exception {
	/**
	 * @param {string} signatureAreaId
	 */
	constructor (signatureAreaId) {
		const message = `The signature area with the ID ${signatureAreaId} doesn't exist`
		super(message, 'UnknownSignatureAreaException')
	}
}
