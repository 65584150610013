// ============================================================================
// SignatureApi
// -------------------
// Signature api
// ============================================================================
// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'
// -------
// Exports
// -------
export default {
	document: {
		version: {
			signature: {
				create: function (vendorId, documentId, data = null, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId, params: params })
					return Backend.POST(URLS.api.signatures.uri, [], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: NotImplementedYet,
				status: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, versionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
						return Backend.GET(
							URLS.api.vendors.documents.versions.signatures.uri,
							[vendorId, documentId, versionId],
							params,
							doCancelPreviousRequest,
							doCancelAllOtherRequests
						)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			},

			signatures: {
				create: NotImplementedYet,
				read: function (vendorId, documentId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId })
					return Backend.GET(URLS.api.vendors.documents.signatures.uri, [vendorId, documentId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			signatories: {
				create: NotImplementedYet,
				read: function (accountingFirmId, vendorId, documentId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId, documentId: documentId })
					return Backend.GET(
						URLS.api.signatures.documents.signatories.uri,
						[accountingFirmId, documentId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	},
	signatory: {
		create: NotImplementedYet,
		read: function (email, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
			RequiredArguments({ email: email })
			return Backend.GET(URLS.api.userByEmail.uri, [email], params, doCancelPreviousRequest, doCancelAllOtherRequests)
		},
		update: NotImplementedYet,
		delete: NotImplementedYet
	},
	authentication: {
		url: {
			create: NotImplementedYet,
			read: function (oauthProviderIdentifier, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ oauthProviderIdentifier: oauthProviderIdentifier, params: params })
				return Backend.GET(URLS.api.oauth.providers.authUrl.uri, [oauthProviderIdentifier], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		},
		user: {
			status: {
				create: NotImplementedYet,
				read: function (oauthProviderIdentifier, userId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ oauthProviderIdentifier: oauthProviderIdentifier, userId: userId })
					return Backend.GET(URLS.api.oauth.providers.status.uri, [oauthProviderIdentifier, userId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	}
}
